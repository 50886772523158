import React, {FC} from 'react';
import {useTimeout} from "../../hooks/useTimeout";
import {Cross20Svg} from "../../svg";

interface IProps {
    close: () => any,
    children: any
}

export const Toast: FC<IProps> = (props) => {
    useTimeout(props.close, 2000);


    return (
        <div className="toast">
            <div className="toast__text">{props.children}</div>
            <div onClick={props.close} className="toast__close-btn">
                <Cross20Svg/>
            </div>
        </div>
    );
};